<template>
    <div class="number_pagination_wrap" v-if="paginationData.total > 0">
        <div class="page_btn_wrap el-page" v-if="returnLastPage() > 1">
            <button class="first_end" :disabled="page === 1" @click="changePage('page',page=1)"></button>
            <button class="previous" :disabled="page === 1" @click="changePage('page',page-1)"></button>
            <el-pagination
                :hide-on-single-page=true
                :page-size="paginationData.size"
                :current-page.sync="page"
                :pager-count="11"
                :total="paginationData.total"
                @current-change="changePage('page', page)"
                class="page_btn_wrap"
                layout="pager"
            >
            </el-pagination>
            <button class="next" :disabled="page === returnLastPage()" @click="changePage('page',page+1)"></button>
            <button class="last_end" :disabled="page === returnLastPage()" @click="changePage('page',page=returnLastPage())"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "LazyloadLoadingLayout",
    mixins: [],
    components: {
    },
    inject: ['setPage'],
    provide() {
        return{
        }
    },
    props: {
        paginationData: {default: {page: 1, size: 10, total: 0,}},
        listLength: {default: 0},
        finishGetList: {default: true}
    },
    data() {
        return{
            page: 1,
            size: 10,
            sizeList: [10, 30, 50],
          maxPage: 0,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.page = this.paginationData.page;
        this.size = this.paginationData.size;
      this.maxPage = Math.ceil(this.paginationData.total / this.paginationData.size + 1)

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        changePage(key, value) {
            this.setPage(key, value);
        },
        returnLastPage() {
            return Math.ceil(this.paginationData.total/this.paginationData.size);
        },
    },
    watch: {
        'paginationData.page': {
            deep: true,
            handler: function (val, oldVal) {
                this.page = this.paginationData.page;
            }
        }
    },
}
</script>

<style scoped>

</style>
